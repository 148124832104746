// styles
import './Infos.scss';

function Infos() {
    return (
        <div className={'Infos col-11 col-md-10 mx-auto my-3 mb-sm-4'}>
            <div className="Info-container m-auto">
                <div className="Info-content col-10 mx-auto my-3 card border-0 shadow p-3">
                    <header className="card-header border-0 bg-transparent">
                        <h3 className="text-center">Quelques informations</h3>
                    </header>
                    <div className="content card-body text-justify">
                        <p>
                            Ce générateur de résolutions vous est offert par les agences Com3elles et Capt’in qui vous souhaitent une excellente année, que vous teniez vos résolutions ou non !
                        </p>
                        <p>
                            Aucun bulot, poney ou autre être vivant n'a été maltraité durant sa conception et nous vous invitons à ne pas maltraiter ces pauvres bêtes !
                        </p>
                        <p>
                            Aucune donnée personnelle n'est stockée par cette application, les résolutions sont stockées dans la mémoire du navigateur, elles seront perdues si vous videz votre cache.
                        </p>
                        <p>
                            Vous avez aimé ? Vous passez un bon moment ? Partagez avec vos amis.
                        </p>
                        <p>
                            Des suggestions d'améliorations ?<br />
                            Écrivez à <a className="text-secondary" href="mailto:resolutron@com3elles.com">resolutron@com3elles.com</a><br />
                            Bonne année 2024 ! <br />
                        </p>
                        <p className="text-center fw-bold">
                            <a className="text-reset" href="https://www.captin.fr/" target={"_blank"} rel="noreferrer">Capt’in</a> et <a className="text-reset" href="https://www.com3elles.com/" target={"_blank"} rel="noreferrer">Com3elles</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Infos;
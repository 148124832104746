import logo from '../../assets/images/logo.png';

function Logo() {
    return (
        <a className='Logo-container' href="/">
            <img className='Logo d-block col-7 mx-auto mt-2' src={logo} alt="logo résolutron" srcSet="" />
        </a>
    )
}

export default Logo;
import {useState} from 'react';
// functions
import {favoriteExists, setPhrase} from '../functions';
// types
import {resolutionList, resolutronData, tag} from '../types';

function useGeneratorHook() {
    const [generatedList, setGeneratedList] = useState<resolutionList>([]);
    const [isInit, setIsInit] = useState(true);
    const [favoritesList, setFavoritesList] = useState<() => resolutionList>(() => {
        const saved = localStorage.getItem('com3_resolutions');
        const initialValue = JSON.parse(saved!);
        return initialValue || [];
    });

    let favorites = favoritesList as unknown as resolutionList;

    const generateResolution = (data: resolutronData, tag: tag | null) => {
        setIsInit(false);

        let phrase = setPhrase(data, generatedList, tag);

        while (favoriteExists(favorites, phrase)) {
            phrase = setPhrase(data, generatedList, tag);
        }

        setGeneratedList((prevList) => {
            switch (prevList.length) {
                case 0:
                    return [{id: Date.now(), resolution: phrase}];
                case 5:
                    prevList.shift();
                    return [...prevList, {id: Date.now(), resolution: phrase}];
                default:
                    return [...prevList, {id: Date.now(), resolution: phrase}];
            }
        })
    }

    return {generatedList, isInit, setFavoritesList, generateResolution}
}

export default useGeneratorHook;
import {defaultData} from "./data";

export const getData = async () => {
    try {
        const options = {
            method: 'GET'
        };

        const response = await fetch('https://app.resolutron.fr/api/resolutions', options);

        const responseData = await response.json();

        if (!response.ok) {
            throw new Error('Unable to fetch resolutions');
        }

        return responseData;
    } catch (e) {
        console.error(e);

        return defaultData;
    }
}

// react share
import React, {useEffect, useState} from 'react';
import {
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from 'react-share';
// components
import ShareApiButton from './ShareApiButton';
// style
import './ShareModal.scss';

type Props = {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
    showModal: boolean,
    resolution: string
}

function ShareModal({setShowModal, showModal, resolution}: Props) {
    const [canShare, setCanShare] = useState<any | undefined>(undefined);
    const [isOnline, setIsOnline] = useState(true);

    const url = "https://resolutron.fr";

    useEffect(() => {
        setCanShare(navigator.share);

        const setOnlineStatus = function (event: Event) {
            if (event.type === 'online') {
                setIsOnline(true);
            } else if (event.type === 'offline') {
                setIsOnline(false);
            }
        }

        window.addEventListener('online', setOnlineStatus);
        window.addEventListener('offline', setOnlineStatus);

        return () => {
            window.removeEventListener('online', setOnlineStatus);
            window.removeEventListener('offline', setOnlineStatus);
        }
    }, []);

    const shareData = {
        title: "Voici ma nouvelle résolution pour 2024 !",
        text: resolution,
        url: "https://resolutron.fr"
    }

    const handleClose = () => {
        document.getElementById('root')?.classList.remove('modal-active');
        setShowModal(false);
    }

    if (showModal) {
        return (
            <div
                className="ShareModal card border-0 shadow p-4 col-10 col-sm-8 col-md-6 col-lg-3 mx-auto my-auto bg-dark text-white">
                <div className="card-head">
                    <h5 className="text-center">Partagez votre nouvelle résolution avec vos amis !</h5>
                </div>

                {isOnline && (
                    <div className="card-body my-1 d-flex justify-content-around">
                        <FacebookShareButton
                            className="btn btn-primary rounded-circle" url={url}
                            quote={"Ma résolution pour 2024 est :\n" + resolution}
                            hashtag="#résolutron"
                        >
                            <FacebookIcon round={true} size={32}/>
                        </FacebookShareButton>
                        <TwitterShareButton
                            className="btn btn-info rounded-circle"
                            url={url}
                            title={"Ma résolution pour 2024 est :\n" + resolution + "\n"}
                            via="Com3elles @CaptinFr"
                            hashtags={["resolutron"]}
                        >
                            <TwitterIcon round={true} size={32}/>
                        </TwitterShareButton>
                        <WhatsappShareButton
                            className="btn btn-success rounded-circle"
                            url={url}
                            title={"Ma résolution pour 2024 est : \n" + resolution}
                        >
                            <WhatsappIcon round={true} size={32}/>
                        </WhatsappShareButton>
                        {
                            canShare && <ShareApiButton data={shareData}/>
                        }
                    </div>
                )}

                {!isOnline && (
                    <div className="card-body my-1">
                        <p className='m-0'>Le partage n'est pas disponnible hors ligne, veuillez vous connecter.</p>
                    </div>
                )}

                <div className="card-footer border-0 text-center">
                    <button onClick={handleClose} className="btn btn-primary shadow-sm">Fermer</button>
                </div>
            </div>
        )
    }

    return (
        <></>
    )
}

export default ShareModal;
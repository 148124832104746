// types
import {resolutionItem, resolutionList, resolutronData, resolutronDefaultProperty, tag} from './types';

/**
 * set random int including min, excluding max
 *
 * @param min
 * @param max
 * @returns int
 */
const getRandomInt = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
}

export {getRandomInt};

/**
 * check if favorite already exists in localStorage
 *
 * @param favorites
 * @param resolution
 * @returns boolean
 */
const favoriteExists = (favorites: resolutionList, resolution: string): boolean => {
    return favorites.some((favorite) => {
        return favorite.resolution === resolution;
    });
}

export {favoriteExists};

const setPhrase = (data: resolutronData, generatedList: resolutionList, tag: tag | null = null) => {
    // select random tag
    if (tag === null) {
        tag = data.tags[getRandomInt(0, data.tags.length)];
    }

    // filter by tag
    const filtered: { [key: string]: resolutronDefaultProperty[] } = {
        verbe: data.verbes.filter(verbe => verbe.tags.includes(tag!.id)),
        action: data.actions.filter(action => action.tags.includes(tag!.id)),
        objet: data.objets.filter(objet => objet.tags.includes(tag!.id)),
        complement: data.complements.filter(complement => complement.tags.includes(tag!.id)),
    };

    let words: { [key: string]: string } = {
        verbe: filtered.verbe[getRandomInt(0, filtered.verbe.length)].name,
        action: filtered.action[getRandomInt(0, filtered.action.length)].name,
        objet: filtered.objet[getRandomInt(0, filtered.objet.length)].name,
        complement: filtered.complement[getRandomInt(0, filtered.complement.length)].name
    };

    let newValue = '';

    // check if words already exist in generatedList
    Object.values(generatedList).forEach(item => {
        for (const [k, v] of Object.entries(words)) {
            if (item.resolution.toLowerCase().includes(v)) {
                newValue = filtered[k][getRandomInt(0, filtered[k].length)].name;
                // if new value is same as value or exists in resolutionList, reset newValue (set limit to avoid infinite loop)
                let i = 0;
                const limit = 10;

                // !check eslint error
                // eslint-disable-next-line no-loop-func
                while ((newValue === v || Object.values(generatedList).find((i) => i.resolution.toLowerCase().includes(newValue))) && i <= limit) {
                    newValue = filtered[k][getRandomInt(0, filtered[k].length)].name;
                    i++;
                }

                words[k] = newValue;
            }
        }
    })

    let phrase = `${words.verbe} ${words.action} ${words.objet} ${words.complement}.`;

    return phrase.charAt(0).toUpperCase() + phrase.slice(1);
}

export {setPhrase};

const setFav = (
    storedFavorites: any,
    item: resolutionItem,
    updateFavList: (value: React.SetStateAction<() => resolutionList>) => void
) => {
    storedFavorites.push(item);
    localStorage.setItem('com3_resolutions', JSON.stringify(storedFavorites));
    updateFavList(storedFavorites);
}

export {setFav};

const unFav = (
    storedFavorites: any,
    item: resolutionItem,
    updateFavList: (value: React.SetStateAction<() => resolutionList>) => void
) => {
    const targetIndex = storedFavorites.findIndex((o: { id: number | undefined; }) => o.id === item.id);
    storedFavorites.splice(targetIndex, 1);
    localStorage.setItem('com3_resolutions', JSON.stringify(storedFavorites));
    updateFavList(storedFavorites);
}

export {unFav};
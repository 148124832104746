import './Presentation.scss';

function Presentation() {
    return (
        <div className="Presentation m-auto card border-0 shadow p-3">
            <header className="card-header border-0 bg-transparent">
                <h3 className="text-center">Partagez vos nouvelles résolutions pour cette année 2024</h3>
            </header>
            <div className="content card-body">
                <p className="text-justify">
                    Bienvenue dans le générateur de résolutions 2024 !
                    Cliquez ci-dessous : si la résolution vous plait, mettez-la en favori ou partagez-la avec vos amis.
                    Sinon, générez-en d’autres à volonté.
                    Amusez-vous !

                </p>
            </div>
        </div>
    )
}

export default Presentation;
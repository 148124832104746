// styles
import './GeneratorBtn.scss';

type Props = {
    callback: () => void
}

function GeneratorButton({callback}: Props) {

    return (
        <button
            className='GeneratorBtn col-12 py-3 px-2 mt-3 text-center border-0'
            onClick={callback}
        >
            Générer une résolution pour 2024
        </button>
    )
}

export default GeneratorButton;
// fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinus} from '@fortawesome/free-solid-svg-icons';
// functions
import {favoriteExists, unFav} from '../../../functions';
// types
import {resolutionItem} from "../../../types";

type Props = {
    updateFavList: any,
    item: resolutionItem
}

function UnFavButton({updateFavList, item}: Props) {

    const handleClick = () => {
        let storedFavorites = JSON.parse(localStorage.getItem('com3_resolutions')!);
        if (favoriteExists(storedFavorites, item.resolution)) {
            unFav(storedFavorites, item, updateFavList);
        }

        return;
    }

    return (
        <button className={'UnFavBtn btn btn-warning shadow-sm m-1 rounded-circle'} onClick={handleClick}>
            <FontAwesomeIcon icon={faMinus}/>
        </button>
    )
}

export default UnFavButton;
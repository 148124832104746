// fontawesome
// router
import {Link, useLocation} from 'react-router-dom';
// styles
import './Nav.scss';
// icons
import HomeIcon from "./Icons/HomeIcon";
import FavoriteIcon from "./Icons/FavoriteIcon";
import InfoIcon from "./Icons/InfoIcon";

function Nav() {
    const location = useLocation();

    const {pathname} = location;

    const splitLocation = pathname.split('/');

    return (
        <nav className="Nav d-flex justify-content-around p-3 mt-2">
            <Link to="/" className={splitLocation[1] === "" ? "active" : ""}>
                <HomeIcon width={27.5}/>
            </Link>
            <Link to="/favoris" className={splitLocation[1] === "favoris" ? "active" : ""}>
                <FavoriteIcon width={27.5}/>
            </Link>
            <Link to="/infos" className={splitLocation[1] === "infos" ? "active" : ""}>
                <InfoIcon width={27.5}/>
            </Link>
        </nav>
    )
}

export default Nav;
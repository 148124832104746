import {resolutronData} from "./types";

const defaultData: resolutronData = {
    success: true,
    tags: [
        {id: 'sport', name: 'sport'},
        {id: 'nourriture', name: 'nourriture'},
        {id: 'confection', name: 'confection'},
        {id: 'collection', name: 'collection'},
        {id: 'photo', name: 'photo'},
        // {id: 'exploration', name: 'exploration'}
    ],
    verbes: [
        // {id: "", name: "démarrer", tags: ['sport', 'confection', 'nourriture', 'exploration', 'collection']},
        // {id: "", name: "ne pas démarrer", tags: ['sport', 'confection', 'nourriture', 'exploration', 'collection', 'photo]},
        // {id: "", name: "ne plus surveiller", tags: ['sport', 'confection', 'photo']},
        // {id: "", name: "expérimenter", tags: ['sport', 'confection', 'nourriture', 'exploration', 'collection', 'photo']},
        // {id: "", name: "ne pas commencer", tags: ['sport', 'confection', 'exploration', 'collection', 'photo']},
        // {id: "", name: "ne pas essayer", tags: ['sport', 'confection', 'nourriture', 'exploration', 'collection', 'photo']},
        // {id: "", name: "ne pas développer", tags: ['sport', 'confection', 'collection', 'photo']},
        {id: "", name: "commencer", tags: ['sport', 'confection', 'collection', 'photo']},
        {id: "", name: "arrêter", tags: ['sport', 'confection', 'nourriture', 'collection', 'photo']},
        {id: "", name: "ne pas arrêter", tags: ['sport', 'confection', 'nourriture', 'collection', 'photo']},
        {id: "", name: "essayer", tags: ['sport', 'confection', 'nourriture', 'collection', 'photo']},
        {id: "", name: "tester", tags: ['sport', 'collection', 'photo']},
        {id: "", name: "me lancer dans", tags: ['sport', 'confection', 'collection', 'photo']},
        {id: "", name: "développer", tags: ['sport', 'confection', 'photo']},
        {id: "", name: "retrouver mon chemin vers", tags: ['sport', 'confection', 'photo']},
        {id: "", name: "reprendre", tags: ['sport', 'confection', 'nourriture', 'collection', 'photo']},
        {id: "", name: "stopper définitivement", tags: ['sport', 'confection', 'nourriture', 'collection', 'photo']},
        {id: "", name: "optimiser", tags: ['sport', 'confection', 'photo']},
        {id: "", name: "envisager", tags: ['sport', 'confection', 'nourriture', 'collection', 'photo']},
    ],
    actions: [
        // {id: "", name: "la course de", tags: ['sport']},
        // {id: "", name: "la culture de", tags: ['nourriture']},
        {id: "", name: "la consommation de", tags: ['nourriture']},
        {id: "", name: "la collection de", tags: ['collection']},
        {id: "", name: "la cueillette de", tags: ['collection']},
        {id: "", name: "l'entassement de", tags: ['collection']},
        {id: "", name: "l'attroupement de", tags: ['collection']},
        {id: "", name: "ma quête de", tags: ['exploration']},
        {id: "", name: "la recherche de", tags: ['exploration']},
        {id: "", name: "la découverte de", tags: ['exploration']},
        {id: "", name: "l'exploration de", tags: ['exploration']},
        {id: "", name: "la compétition de cracher de", tags: ['nourriture']},
        {id: "", name: "l'entraînement de lancer de", tags: ['sport', 'nourriture']},
        {id: "", name: "la compétition de lancer de", tags: ['sport', 'nourriture']},
        {id: "", name: "l'entraînement au saut de", tags: ['sport']},
        {id: "", name: "la compétition de saut de", tags: ['sport']},
        {id: "", name: "l'entraînement au jeté de", tags: ['sport', 'nourriture']},
        {id: "", name: "la compétition de jeté de", tags: ['sport', 'nourriture']},
        {id: "", name: "les cours de", tags: ['sport']},
        {id: "", name: "la confection de pulls pour", tags: ['confection']},
        {id: "", name: "la fabrication de bobs pour", tags: ['confection']},
        {id: "", name: "la fabrication de strings pour", tags: ['confection']},
        {id: "", name: "la photographie de", tags: ['photo']},
        {id: "", name: "la prise de selfies avec des", tags: ['photo']},
        {id: "", name: "la cinématographie de", tags: ['photo']}
    ],
    objets: [
        // {id:"", name: "grolandais", tags: ['photo']},
        // {id:"", name: "tricots en cheveux", tags: ['confection']},
        // {id:"", name: "ricochets sur sable", tags: ['sport', 'photo']},
        // {id:"", name: "canoë freestyle", tags: ['sport']},
        // {id:"", name: "moustaches frisées", tags: []},
        // {id:"", name: "tondeuses à ongles", tags: []},
        // {id:"", name: "tennis", tags: ['sport']},
        {id: "", name: "bulots", tags: ['nourriture', 'photo', 'collection']},
        {id: "", name: "cigarettes carrées", tags: ['confection', 'collection']},
        {id: "", name: "poneys en peluche", tags: ['confection', 'photo', 'collection']},
        {id: "", name: "shampooings en poudre", tags: ['nouriture', 'collection']},
        {id: "", name: "culbutos", tags: ['nourriture', 'photo', 'collection']},
        {id: "", name: "loutres albinos", tags: ['nourriture', 'photo', 'collection']},
        {id: "", name: "fromages corses", tags: ['nourriture', 'collection']},
        {id: "", name: "raclettes véganes", tags: ['nourriture']},
        {id: "", name: "tongues en chanvre", tags: ['confection', 'collection']},
        {id: "", name: "puces acrobates", tags: ['photo', 'collection']},
        {id: "", name: "concombres", tags: ['nourriture', 'collection', 'photo']},
        {id: "", name: "téléphones portables", tags: ['sport', 'collection']},
        {id: "", name: "troncs de bambou", tags: ['sport', 'collection', 'nourriture']},
    ],
    complements: [
        {id: "", name: "les yeux bandés", tags: ['photo', 'sport']},
        {id: "", name: "en apnée", tags: ['sport', 'confection', 'photo']},
        {id: "", name: "à reculons", tags: ['sport', 'confection']},
        {id: "", name: "avec les dents", tags: ['sport', 'confection']},
        {id: "", name: "sans les dents", tags: ['sport', 'confection']},
        {id: "", name: "à cloche pied", tags: ['sport', 'confection', 'photo']},
        {id: "", name: "sur une main", tags: ['sport', 'confection', 'photo']},
        {id: "", name: "sur un pied", tags: ['sport', 'confection', 'photo']},
        {id: "", name: "en procrastinant", tags: ['sport', 'confection']},
        {id: "", name: "avec entrain", tags: ['sport']},
        {id: "", name: "le 29 février", tags: ['sport', 'confection', 'nourriture', 'collection', 'photo']},
        {
            id: "",
            name: "les jours impairs des mois pairs",
            tags: ['sport', 'confection', 'nourriture', 'collection', 'photo']
        },
        {id: "", name: "en salle", tags: ['sport']},
        {id: "", name: "sans cligner des yeux", tags: ['sport', 'confection']},
        {id: "", name: "en chaussettes", tags: ['sport', 'confection']},
        {id: "", name: "depuis mon canapé", tags: ['sport', 'confection', 'collection', 'photo']},
        {id: "", name: "dans ma chambre", tags: ['sport', 'nourriture', 'photo']},
        {id: "", name: "dans mon frigo", tags: ['sport', 'confection', 'collection', 'photo']},
        {id: "", name: "en slip", tags: ['sport', 'confection', 'nourriture', 'collection', 'photo']},
        {id: "", name: "en string", tags: ['sport', 'confection', 'nourriture', 'collection', 'photo']},
        {id: "", name: "sans les mains", tags: ['sport', 'confection', 'nourriture', 'collection', 'photo']},
    ]
};

export {defaultData};
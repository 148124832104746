import { useEffect } from 'react';
// router
import { Outlet } from 'react-router-dom';
// styles
import './App.scss';
// components
import Logo from '../Logo';
import Footer from '../Footer';
import ReactPWAInstallProvider from 'react-pwa-install';
import PwaInstaller from '../PwaInstaller';

function App() {

  useEffect(() => {
    // effect
    if (!localStorage.getItem('com3_resolutions')) {
      localStorage.setItem('com3_resolutions', "[]");
    }
  }, []);

  return (
    <ReactPWAInstallProvider enableLogging>
      <div className={'App pos-relative shadow col-11 col-md-10 col-lg-6 col-xl-4'}>
        <PwaInstaller />
        <Logo />
        <Outlet />
        <Footer />
      </div>
    </ReactPWAInstallProvider>
  )
}

export default App;
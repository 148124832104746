import React, {useState} from 'react';
// components
import GeneratedList from './GeneratedList';
import GeneratorButton from './GeneratorButton';
import Presentation from './Prensentation';
import TagSelector from "./TagSelector";
// custom hooks
import useGeneratorHook from '../../hooks/useGeneratorHook';
// types
import {resolutronData, tag} from "../../types";

type Props = {
    data: resolutronData,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
    setActiveResolution: React.Dispatch<React.SetStateAction<string>>
}

function Generator({data, setShowModal, setActiveResolution}: Props) {
    const {generatedList, isInit, setFavoritesList, generateResolution} = useGeneratorHook();
    const [tag, setTag] = useState<tag | null>(null)

    const handleTagChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selected = data.tags.find(x => x.id === e.target.value) ?? null
        setTag(selected)
    }

    return (
        <div className={'Generator col-11 col-md-10 mx-auto my-3'}>
            {isInit &&
                <Presentation/>
            }
            {!isInit &&
                <>
                    <TagSelector tagList={data.tags} onChange={handleTagChange}/>
                    <GeneratedList
                        list={generatedList}
                        setFavoritesList={setFavoritesList}
                        setShowModal={setShowModal}
                        setActiveResolution={setActiveResolution}
                    />
                </>
            }
            <GeneratorButton callback={() => generateResolution(data, tag)}/>
        </div>
    )
}

export default Generator;
import React, {useEffect, useState} from 'react'
import ReactDOM from 'react-dom';
// service worker
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// webvitals
import reportWebVitals from './reportWebVitals';
// router
import {BrowserRouter, Route, Routes} from 'react-router-dom';
// components
import App from './components/App';
import Generator from '../src/components/Generator';
import Favorites from '../src/components/Favorites';
import Infos from '../src/components/Infos';
import ShareModal from '../src/components/ShareModal';
// style
import './index.scss';
// data
import {defaultData} from "./data";
import {getData} from "./api";
// bootstrap
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import {resolutronData} from "./types";

let didInit = false;

function Root() {
    const [data, setData] = useState<resolutronData>(defaultData)
    const [showModal, setShowModal] = useState(false);
    const [activeResolution, setActiveResolution] = useState('');

    // load data once
    useEffect(() => {
        if (!didInit) {
            didInit = true;
            getData().then(newData => setData(newData));
        }
    }, []);

    return (
        <React.StrictMode>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<App/>}>
                        <Route
                            path=""
                            element={
                                <Generator
                                    data={data}
                                    setShowModal={setShowModal}
                                    setActiveResolution={setActiveResolution}
                                />
                            }
                        />
                        <Route
                            path="favoris"
                            element={
                                <Favorites
                                    setShowModal={setShowModal}
                                    setActiveResolution={setActiveResolution}
                                />
                            }
                        />
                        <Route path="infos" element={<Infos/>}/>
                        <Route
                            path="*"
                            element={
                                <main
                                    className='NoContent card border-0 rounded shadow-sm col-10 mx-auto my-5 text-center'>
                                    <p className='p-4 m-0'>Aucun contenu à afficher !</p>
                                </main>
                            }
                        />
                    </Route>
                </Routes>
            </BrowserRouter>
            <ShareModal setShowModal={setShowModal} showModal={showModal} resolution={activeResolution}/>
        </React.StrictMode>
    )
}

ReactDOM.render(<Root/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// styles
import './FavoriteIcon.scss';

type Props = {
    width: number;
}

function FavoriteIcon({width}: Props) {
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={width}
            className={"FavoriteIcon"}
        >
            <path className="cls-1"
                  d="m11.89,21.46c-.45-.32-11.09-7.88-11.09-13.95,0-2.57,1.49-4.57,3.88-5.21.53-.14,1.08-.21,1.63-.21,1.94,0,3.81.88,5.11,2.41l.58.68.57-.68c1.26-1.5,3.1-2.36,5.05-2.36.55,0,1.1.07,1.64.21,2.43.63,3.94,2.61,3.94,5.17,0,6.07-10.64,13.64-11.09,13.96l-.1.07-.11-.07Z"/>
            <path className="cls-2"
                  d="m6.31,2.83c1.73,0,3.38.78,4.54,2.14l1.15,1.35,1.14-1.36c1.11-1.33,2.75-2.09,4.48-2.09.49,0,.98.06,1.45.18,2.12.55,3.38,2.21,3.38,4.45,0,4.42-6.66,10.37-10.45,13.11C8.2,17.87,1.55,11.92,1.55,7.5c0-2.25,1.24-3.93,3.32-4.48.47-.13.95-.19,1.44-.19h0m0-1.5c-.61,0-1.22.08-1.83.24C1.75,2.3.05,4.58.05,7.5.05,14,11,21.74,11.46,22.07l.54.38.54-.38c.47-.33,11.41-8.07,11.41-14.57,0-2.92-1.73-5.18-4.51-5.9-.61-.16-1.22-.23-1.83-.23-2.16,0-4.23.96-5.63,2.63-1.44-1.7-3.53-2.67-5.68-2.67h0Z"/>
        </svg>
        // <svg
        //     id="Layer_1"
        //     xmlns="http://www.w3.org/2000/svg"
        //     viewBox="0 0 24 24"
        //     width={width}
        //     className={"FavoriteIcon"}
        // >
        //     <path className="cls-1"
        //           d="m11.75,21.66c-.46-.32-11.2-7.96-11.2-14.16,0-2.69,1.56-4.78,4.06-5.45.55-.15,1.12-.22,1.7-.22,2.02,0,3.95.91,5.3,2.49l.38.45.38-.45c1.31-1.56,3.22-2.45,5.24-2.45.57,0,1.14.07,1.7.22,2.55.66,4.13,2.73,4.13,5.41,0,6.19-10.74,13.84-11.2,14.16l-.25.17-.25-.18Z"/>
        //     <path className="cls-2"
        //           d="m6.31,2.33c1.87,0,3.66.84,4.92,2.32l.77.9.76-.91c1.21-1.44,2.98-2.27,4.86-2.27.53,0,1.06.07,1.58.2,2.35.61,3.76,2.45,3.76,4.93,0,4.74-7.14,11.03-10.95,13.72C8.19,18.53,1.05,12.24,1.05,7.5c0-2.49,1.38-4.35,3.69-4.96.51-.14,1.04-.21,1.57-.21h0m0-1c-.61,0-1.22.08-1.83.24C1.75,2.3.05,4.58.05,7.5.05,14,11,21.74,11.46,22.07l.54.38.54-.38c.47-.33,11.41-8.07,11.41-14.57,0-2.92-1.73-5.18-4.51-5.9-.61-.16-1.22-.23-1.83-.23-2.16,0-4.23.96-5.63,2.63-1.44-1.7-3.53-2.67-5.68-2.67h0Z"/>
        // </svg>
    )
}

export default FavoriteIcon;
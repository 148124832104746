import React, { useEffect, useRef } from 'react';
// components
import FavoriteButton from '../../Buttons/FavoriteButton';
import ShareButton from '../../Buttons/ShareButton';
// types
import { resolutionList } from '../../../types';
// styles
import './GeneratedList.scss';
// animations
import { animated, useTransition } from '@react-spring/web';

type Props = {
    list: resolutionList,
    setFavoritesList: React.Dispatch<React.SetStateAction<() => resolutionList>>,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
    setActiveResolution: React.Dispatch<React.SetStateAction<string>>
}

function GeneratedList({ list, setFavoritesList, setShowModal, setActiveResolution }: Props) {
    const endRef = useRef<HTMLLIElement>(null);

    const scrollToBottom = () => {
        if (endRef.current) {
            endRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    useEffect(scrollToBottom, [list]);

    const transition = useTransition(list, {
        from: { opacity: 0, marginLeft: -100, marginTop: 0 },
        enter: { opacity: 1, marginLeft: 0 }
    });

    return (
        <>
            {
                list.length > 0 &&
                // <ul className="GeneratedList m-0 p-0 fw-bold">
                <ul className="GeneratedList fw-bold m-0 p-2 shadow-sm rounded">
                    {transition((styles, item) => (
                        <animated.li className="d-flex flex-wrap w-100 align-items-center mb-4 p-2 p-sm-3 shadow-sm" key={item.id} style={styles} >
                            <p className="resolution m-auto col-10">{item.resolution}</p>
                            <div className="actions m-auto col-2 d-flex flex-wrap justify-content-end">
                                <FavoriteButton
                                    updateFavList={setFavoritesList}
                                    item={item}
                                />
                                <ShareButton
                                    item={item}
                                    setShowModal={setShowModal}
                                    setModalResolution={setActiveResolution}
                                />
                            </div>
                        </animated.li>
                    ))}
                    <li ref={endRef} />
                </ul>
            }
        </>
    )
}

export default GeneratedList;
import {animated, useTransition} from '@react-spring/web';
import {useState} from 'react';
// components
import ShareButton from '../../Buttons/ShareButton';
import UnFavButton from '../../Buttons/UnFavButton';
// types
import {resolutionList} from '../../../types';
// style
import './FavoritesList.scss';

type Props = {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
    setModalResolution: React.Dispatch<React.SetStateAction<string>>
}

function FavoritesList({setShowModal, setModalResolution}: Props) {
    const [favoritesList, setFavoritesList] = useState<() => resolutionList>(() => {
        const saved = localStorage.getItem('com3_resolutions');
        const initialValue = JSON.parse(saved!);
        return initialValue || [];
    });

    let favorites = favoritesList as unknown as resolutionList;

    const transition = useTransition(favorites, {
        from: {opacity: 0, marginTop: -100},
        enter: {opacity: 1, marginTop: 0},
        trail: 30
    });

    if (favorites.length > 0) {
        return (
            <ul className="FavoritesList fw-bold m-0 p-2 shadow-sm rounded">
                {transition((styles, item) => (
                    <animated.li className="d-flex flex-wrap w-100 align-items-center mb-4 p-2 p-sm-3 shadow-sm"
                                 key={item.id} style={styles}>
                        <p className="resolution m-auto col-10">{item.resolution}</p>
                        <div className="actions m-auto col-2 d-flex flex-wrap justify-content-end">
                            <UnFavButton
                                updateFavList={setFavoritesList}
                                item={item}
                            />
                            <ShareButton
                                item={item}
                                setShowModal={setShowModal}
                                setModalResolution={setModalResolution}
                            />
                        </div>
                    </animated.li>
                ))}
            </ul>
        )
    }

    return (
        <h4 className="Favorites-info card border-0 m-auto p-4 text-center shadow">Découvrez vos favoris ici !</h4>
    )
}

export default FavoritesList;
// styles
import './InfoIcon.scss';

type Props = {
    width: number;
}

function InfoIcon({width}: Props) {
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={width}
            className={"InfoIcon"}
        >
            <path className="cls-1"
                  d="m9.46,12.42c.35-.36.79-.54,1.32-.54,1.35,0,2.44-.32,3.26-.95.82-.64,1.23-1.62,1.23-2.95,0-1.03-.37-1.88-1.11-2.55-.74-.68-1.66-1.02-2.77-1.02s-2.12.38-2.98,1.14c-.49.45-1.01.68-1.54.68-.39,0-.74-.12-1.05-.37-.53-.41-.8-.89-.8-1.45,0-.47.19-.9.58-1.29.74-.76,1.6-1.35,2.6-1.77.99-.42,2.06-.63,3.18-.63,1.39,0,2.67.32,3.82.95,1.15.64,2.06,1.51,2.74,2.63.68,1.12,1.02,2.34,1.02,3.68,0,1.97-.56,3.53-1.69,4.68-1.13,1.15-2.63,1.87-4.49,2.15-.1.04-.15.09-.15.15v1.14c0,.53-.17.98-.52,1.34-.35.36-.79.54-1.32.54s-.97-.18-1.32-.54c-.35-.36-.52-.8-.52-1.34v-2.34c0-.53.17-.98.52-1.34Z"/>
            <circle className="cls-1" cx="10.81" cy="21.43" r="1.84"/>
        </svg>
        // <svg
        //     id="Layer_1"
        //     xmlns="http://www.w3.org/2000/svg"
        //     viewBox="0 0 24 24"
        //     width={width}
        //     className={"InfoIcon"}
        // >
        //     <path className="cls-1"
        //           d="m9.46,12.42c.35-.36.79-.54,1.32-.54,1.35,0,2.44-.32,3.26-.95.82-.64,1.23-1.62,1.23-2.95,0-1.03-.37-1.88-1.11-2.55-.74-.68-1.66-1.02-2.77-1.02s-2.12.38-2.98,1.14c-.49.45-1.01.68-1.54.68-.39,0-.74-.12-1.05-.37-.53-.41-.8-.89-.8-1.45,0-.47.19-.9.58-1.29.74-.76,1.6-1.35,2.6-1.77.99-.42,2.06-.63,3.18-.63,1.39,0,2.67.32,3.82.95,1.15.64,2.06,1.51,2.74,2.63.68,1.12,1.02,2.34,1.02,3.68,0,1.97-.56,3.53-1.69,4.68-1.13,1.15-2.63,1.87-4.49,2.15-.1.04-.15.09-.15.15v1.14c0,.53-.17.98-.52,1.34-.35.36-.79.54-1.32.54s-.97-.18-1.32-.54c-.35-.36-.52-.8-.52-1.34v-2.34c0-.53.17-.98.52-1.34Z"/>
        //     <circle className="cls-1" cx="10.81" cy="21.43" r="1.84"/>
        // </svg>
    )
}

export default InfoIcon;
// react-pwa-install
import {useReactPWAInstall} from 'react-pwa-install';
// logo
import logo from '../../assets/images/icon-resolutron_192.png';
// fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDownload} from '@fortawesome/free-solid-svg-icons';
// styles
import './PwaInstaller.scss';

function PwaInstaller() {
    const {pwaInstall, supported, isInstalled} = useReactPWAInstall();

    const handleClick = () => {
        pwaInstall({
            title: `Com3'Elles & Capt'In Résolutron 2024`,
            logo: logo,
            description: 'Profitez du Résolutron en format application, même hors ligne !'
        });
    }

    return (
        <>
            {
                supported() && !isInstalled() && (
                    <button className='PwaInstaller btn btn-warning rounded-circle shadow-sm' onClick={handleClick}>
                        <FontAwesomeIcon icon={faDownload}/>
                    </button>
                )
            }
        </>
    )
}

export default PwaInstaller;
import React, {useState} from 'react';
// fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faHeart} from '@fortawesome/free-solid-svg-icons';
// style
import './FavoriteButton.scss';
// types
import {resolutionItem, resolutionList} from '../../../types';
// functions
import {favoriteExists, setFav, unFav} from '../../../functions';

type Props = {
    updateFavList: React.Dispatch<React.SetStateAction<() => resolutionList>>,
    item: resolutionItem,
}

function FavoriteButton({updateFavList, item}: Props) {
    const [isFav, setIsFav] = useState(false);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        let storedFavorites = JSON.parse(localStorage.getItem('com3_resolutions')!);

        if (!favoriteExists(storedFavorites, item.resolution)) {
            setFav(storedFavorites, item, updateFavList);
            setIsFav(true);
        } else {
            unFav(storedFavorites, item, updateFavList);
            setIsFav(false);
        }

        return;
    }


    return (
        <button className={'FavBtn btn btn-danger shadow-sm m-1 rounded-circle'} onClick={handleClick}>
            {isFav && <FontAwesomeIcon icon={faCheck}/>}
            {!isFav && <FontAwesomeIcon icon={faHeart}/>}
        </button>
    )
}

export default FavoriteButton;
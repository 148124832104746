// styles
import './HomeIcon.scss';

type Props = {
    width: number;
}

function HomeIcon({width}: Props) {
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={width}
            className={"HomeIcon"}
        >
            <path className="cls-1"
                  d="m15.79,22.97v-4.06c0-1.37-1.11-2.48-2.48-2.48h-2.1c-1.37,0-2.48,1.11-2.48,2.48v4.06h-3.22c-.88,0-1.59-.71-1.59-1.59v-8.7h-1.37c-.58,0-.94-.37-1.1-.72-.19-.43-.12-.9.2-1.24L10.88,1.49c.3-.3.7-.46,1.12-.46s.82.17,1.12.46l3.6,3.6v-2.26c0-.26.21-.47.47-.47h1.26c.26,0,.47.21.47.47v4.46l3.4,3.39c.36.39.44.85.25,1.28-.15.35-.52.72-1.1.72h-1.37v8.7c0,.88-.71,1.59-1.59,1.59h-2.71Z"/>
            <path className="cls-2"
                  d="m12,1.78c.22,0,.43.09.59.25l2.32,2.32,2.56,2.56v-3.79h.69v4.48l.44.44,1.15,1.15,2.05,2.05c.11.12.14.27.06.43-.04.1-.16.27-.41.27h-2.12v9.45c0,.46-.38.84-.84.84h-1.96v-3.31c0-1.78-1.45-3.23-3.23-3.23h-2.1c-1.78,0-3.23,1.45-3.23,3.23v3.31h-2.47c-.46,0-.84-.38-.84-.84v-9.45h-2.12c-.25,0-.37-.17-.41-.27-.07-.16-.05-.31.09-.45l2.02-2.02,2.06-2.06,5.11-5.11c.16-.16.37-.25.59-.25m0-1.5c-.6,0-1.2.23-1.65.68l-5.11,5.11-2.06,2.06-2.02,2.02s-.04.05-.07.07c-1.13,1.23-.22,3.22,1.45,3.22h.62v7.95c0,1.29,1.05,2.34,2.34,2.34h3.97v-4.81c0-.96.77-1.73,1.73-1.73h2.1c.96,0,1.73.77,1.73,1.73v4.81h3.46c1.29,0,2.34-1.05,2.34-2.34v-7.95h.62c1.67,0,2.58-1.99,1.45-3.22-.02-.02-.04-.05-.07-.07l-2.02-2.02-1.15-1.15V2.83c0-.67-.54-1.22-1.22-1.22h-1.26c-.67,0-1.22.54-1.22,1.22v.45l-2.32-2.32c-.46-.46-1.05-.68-1.65-.68h0Z"/>
        </svg>
        // <svg
        //     id="Layer_1"
        //     xmlns="http://www.w3.org/2000/svg"
        //     viewBox="0 0 24 24"
        //     width={width}
        //     className={"HomeIcon"}
        // >
        //     <path
        //         className="cls-1"
        //         d="m12,1.28c.36,0,.69.14.95.39l2.32,2.32,1.71,1.71v-2.87c0-.12.1-.22.22-.22h1.26c.12,0,.22.1.22.22v4.56l.29.29,1.15,1.15,2.02,2.02.04.04c.35.38.23.8.16.97-.06.13-.3.57-.87.57h-1.62v8.95c0,.74-.6,1.34-1.34,1.34h-2.46v-3.81c0-1.51-1.22-2.73-2.73-2.73h-2.1c-1.51,0-2.73,1.22-2.73,2.73v3.81h-2.97c-.74,0-1.34-.6-1.34-1.34v-8.95h-1.62c-.57,0-.81-.44-.87-.57-.07-.16-.2-.58.16-.97l.04-.04,2.02-2.02,2.06-2.06L11.05,1.67c.25-.25.59-.39.95-.39m0-1c-.6,0-1.2.23-1.65.68l-5.11,5.11-2.06,2.06-2.02,2.02s-.04.05-.07.07c-1.13,1.23-.22,3.22,1.45,3.22h.62v7.95c0,1.29,1.05,2.34,2.34,2.34h3.97v-4.81c0-.96.77-1.73,1.73-1.73h2.1c.96,0,1.73.77,1.73,1.73v4.81h3.46c1.29,0,2.34-1.05,2.34-2.34v-7.95h.62c1.67,0,2.58-1.99,1.45-3.22-.02-.02-.04-.05-.07-.07l-2.02-2.02-1.15-1.15V2.83c0-.67-.54-1.22-1.22-1.22h-1.26c-.67,0-1.22.54-1.22,1.22v.45l-2.32-2.32c-.46-.46-1.05-.68-1.65-.68h0Z"
        //     />
        // </svg>
    )
}

export default HomeIcon;
import React from 'react';
// fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faShareAlt} from '@fortawesome/free-solid-svg-icons';
// types
import {resolutionItem} from "../../../types";

type Props = {
    item: resolutionItem,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
    setModalResolution: React.Dispatch<React.SetStateAction<string>>
}

function ShareButton({item, setShowModal, setModalResolution}: Props) {

    const handleClick = () => {
        document.getElementById('root')?.classList.add("modal-active");
        setShowModal(true);
        setModalResolution(item.resolution)
    }

    return (
        <button className={'btn btn-secondary shadow-sm m-1 rounded-circle'} onClick={handleClick}>
            <FontAwesomeIcon icon={faShareAlt}/>
        </button>
    )
}

export default ShareButton;
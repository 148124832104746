// components
import Nav from '../Nav';
// style 
import './Footer.scss';

function Footer() {
    return (
        <footer className="Footer">
            <Nav />
            <div className="powered-by text-center">
                <p className="m-0 py-1 py-sm-2 fw-lighter">
                    Offert par <a className="text-reset" href="https://www.captin.fr/" target="_blank" rel="noreferrer">Capt'In</a> et <a className="text-reset" href="https://www.com3elles.com/" target="_blank" rel="noreferrer">Com3elles</a>
                </p>
            </div>
        </footer>
    )
}

export default Footer;
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// style
import './ShareApiButton.scss';

type Props = {
    data: {
        title: string,
        text: string,
        url: string
    }
}

function ShareApiButton({ data }: Props) {
    const handleShareApiBtn = async () => {
        try {
            await window.navigator.share(data);
            console.log("Shared successfully")
        } catch (err) {
            console.error("share failed: ", err)
        }
    }

    return (
        <button onClick={handleShareApiBtn} className={'ShareApiBtn btn btn-light rounded-circle'}>
            <FontAwesomeIcon icon={faShareAlt} />
        </button>
    )
}

export default ShareApiButton;
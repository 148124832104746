//  types
import {tag} from "../../../types";
// style
import './TagSelector.scss';

type Props = {
    tagList: tag[],
    onChange: React.ChangeEventHandler<HTMLSelectElement>
}

function TagSelector({tagList, onChange}: Props) {
    return (
        <div className={'TagSelector d-flex justify-content-between align-items-center mb-3'}>
            <label htmlFor={'select-tag'} className={'font-weight-bold'}>Choix du Thème : </label>
            <select id={'select-tag'} className={'form-select'} onChange={onChange}>
                <option value={''}>Aléatoire</option>
                {
                    tagList.length > 0 &&
                    tagList.map(tag => (
                        <option
                            value={tag.id}
                            key={tag.id}
                        >
                            {tag.name.charAt(0).toUpperCase() + tag.name.slice(1)}
                        </option>
                    ))
                }
            </select>
        </div>
    );
}

export default TagSelector;
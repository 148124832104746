import React from 'react';
// components
import FavoritesList from './FavoritesList';

type Props = {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
    setActiveResolution: React.Dispatch<React.SetStateAction<string>>
}

function Favorites({ setShowModal, setActiveResolution }: Props) {
    
    return (
        <div className={'Favorites col-11 col-md-10 mx-auto my-3'}>
            <FavoritesList setShowModal={setShowModal} setModalResolution={setActiveResolution}/>
        </div>
    )
}

export default Favorites;